<template>
        <div class="control-result-block">
            <div
                class="control-result-button"
                :class="{
                    'Stickers-wrapper': isStickers,
                }"
            >
                <sticker-enabled @click="handleCloseStickers" v-if="isStickers"/>
                <sticker @click="handleOpenStickers" v-else/>
                <div v-if="isStickers" class="control-result-dropdown Stickers">
                    <ul>
                        <li
                            class="Stickers-item"
                            v-for="(sticker, key) in stickers"
                            :key="key"
                        >
                            <img
                                @click="handleClickSticker(sticker)"
                                :src="sticker"/>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="control-result-button" @click="handleClickDrawing">
                <spline-enabled v-if="isDrawing"/>
                <spline v-else/>
            </div>
            <div v-if="isVideo" class="control-result-button" @click="handleClickMute">
                <sound-muted v-if="isMuted"/>
                <sound v-else/>
            </div>
            <div
                v-if="canDelete"
                class="control-result-button"
                @click="handleClickDelete"
            >
                <delete />
            </div>
        </div>
</template>

<script>
import Sticker from "@/assets/images/icons/sticker-2.svg?inline";
import StickerEnabled from "@/assets/images/icons/sticker-2-enabled.svg?inline";
import Spline from "@/assets/images/icons/spline-2.svg?inline";
import SplineEnabled from "@/assets/images/icons/spline-2-enabled.svg?inline";
import Sound from "@/assets/images/icons/sound-2.svg?inline";
import SoundMuted from "@/assets/images/icons/sound-muted-2.svg?inline";
import Delete from "@/assets/images/icons/delete-2.svg?inline"

import {STICKER_PATHS} from "@/configs/constants";

export default {
    name: "ViewResultControlBlock",
    data() {
        return {
            stickers: STICKER_PATHS,
            currentSticker: false,
        }
    },
    props: {
        isDrawing: Boolean,
        isMuted: Boolean,
        isStickers: {
            type: Boolean,
            default: false,
        },
        isVideo: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Sticker,
        StickerEnabled,
        Spline,
        SplineEnabled,
        Sound,
        SoundMuted,
        Delete,
    },
    methods: {
        handleStartSticker(sticker) {
          this.currentSticker = sticker
        },
        handleMoveSticker() {
            this.currentSticker = false
        },
        handleEndSticker(sticker) {
            if (sticker === this.currentSticker) {
                this.$emit('selectSticker', this.$event, sticker)
            }
        },
        handleClickSticker(sticker) {
            this.$emit('selectSticker', this.$event, sticker)
        },
        handleClickDrawing() {
            this.$emit('closeStickers')
            this.$emit('toggleDrawing')
        },
        handleClickDelete() {
            this.$emit('deleteSticker')
        },
        handleCloseStickers() {
            this.$emit('closeStickers')
        },
        handleOpenStickers() {
            if (this.isDrawing) {
                this.handleClickDrawing()
            }
            this.$emit('openStickers')
        },
        handleClickMute() {
            this.$emit('clickMute')
        }
    },
}
</script>

<style lang="scss" scoped>
.control-result-block {
    text-align: center;
    margin: 0 0.6em;
    z-index: 101;
}

.control-result-button {
    cursor: pointer;
    position: relative;

    &:not(.first) {
        margin-top: 0.5em;
    }

    svg {
        width: 28px;
        height: 28px;
    }
}

.Stickers {
    display: flex;
    position: absolute;
    height: 300px;
    width: 80px;
    border-radius: 8px;
    border: 1px solid #000000;
    left: -100px;
    top: -10px;
    overflow-y: scroll;
    overflow-x: visible;
    padding: 10px 5px;
    background-color: #FFFFFF;

    &-wrapper::before {
        content: '';
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-left: 10px solid #000000;
        position: absolute;
        right: 28px;
        top: 0px;
    }
    &-wrapper::after {
        content: '';
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-left: 10px solid #FFFFFF;
        position: absolute;
        right: 29px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #FC881B;
        border: 1px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }

    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 8px;
    }

    ul {
        padding: 0;
    }

    &-item {
        list-style-type: none;
        padding: 0px;
        margin-bottom: 10px;

        & img {
            width: 48px;
            height: 48px;
            fill: orange;
        }
    }
}
</style>
