<template>
    <v-btn fab id="send" v-on="$listeners" >
        <svg id="send-icon" xmlns="http://www.w3.org/2000/svg" width="43.993" height="43.993" viewBox="0 0 43.993 43.993">
            <path id="Navigator" d="M27.6,3.986,18.291,30.61a.974.974,0,0,1-.945.651h0a.971.971,0,0,1-.944-.651L11.876,17.672a2.708,2.708,0,0,0-1.742-1.66L-3.439,11.7a.921.921,0,0,1-.681-.9.922.922,0,0,1,.681-.9L24.5,1.026a2.492,2.492,0,0,1,2.513.565A2.219,2.219,0,0,1,27.6,3.986" transform="translate(25.018 2.277) rotate(45)" fill="#fff"/>
        </svg>

    </v-btn>
</template>

<script>
export default {
name: "SendButton"
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables.scss';
#send {
    background: $accent-background;
    width: 64px;
    height: 64px;
}

#send-icon{
    position: relative;
    left: -4px;
}
</style>
